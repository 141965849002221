<template>
  <div class="cell-wrapper">
    <img :src="info.pictureUrl" class="image" />
    <div class="right">
      <div class="title h2 single-line">{{info.title}}</div>
      <div class="content">{{content}}</div>
      <div class="function-footer">
        <div class="button" style="margin-right: 20px;" @click="goDetail">查看详情</div>
        <div class="button" @click="goApply">申请服务</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['info'],
  data() {
    return {}
  },
  computed: {
    content() {
      var content = this.info.contentText
      if (content == null) {
        content = this.info.content
      }
      if (content.length > 50) {
        content = content.substring(0, 50) + '...'
      }
      return content
    }
  },
  created() { },
  mounted() {

  },
  methods: {
    goDetail() {
      this.$emit('goDetail', this.info)
    },
    goApply() {
      this.$emit('goApply', this.info)
    },
  }
}
</script>

<style scoped lang="less">
.cell-wrapper {
  height: 120px;
  display: flex;
  align-items: stretch;
  padding: 30px;
  border-bottom: #dfdfdf 1px solid;
  .image {
    width: 200px;
    height: 100%;
    margin-right: 20px;
  }
  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    .title {
      height: 30px;
    }
    .content {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .function-footer {
      display: flex;
      justify-content: right;
      .button {
        text-align: center;
        height: 40px;
        width: 150px;
        border: solid 1px #dfdfdf;
        line-height: 40px;
        cursor: pointer;
        border-radius: 15px;
      }
    }
  }
}
.cell-wrapper:hover {
  color: #27a5f9;
  background: #eef8ff;
  // transform: scaleX(1.045);
  .button {
    background-color: #0046c0;
    color: #fff;
  }
}
</style>
